<template>
  <div id="share">
    <div class="mine-title">{{ $t("route.share") }}</div>
    <div class="query-form">
      <a-form-model :model="queryForm" ref="QueryForm" @submit="handleSubmit" @submit.native.prevent>
        <a-row :gutter="[20,20]" type="flex">
          <a-col :xxl="8" :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
            <a-form-model-item :label="$t('mine.share_1')" prop="create_date">
              <a-range-picker v-model="queryForm.create_date" :locale="locale" style="width: 100%"/>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="8" :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
            <a-form-model-item :label="$t('mine.share_2')" prop="order_no">
              <a-input v-model="queryForm.order_no" allow-clear :placeholder="$t('mine.share_3')"/>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="8" :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
            <a-form-model-item :label="$t('mine.share_4')" prop="status">
              <a-select v-model="queryForm.status" allow-clear :placeholder="$t('mine.share_5')">
                <a-select-option value="1">
                  {{$t("mine.share_6")}}
                </a-select-option>
                <a-select-option value="2">
                  {{$t("mine.share_7")}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <div class="flex-center-end">
              <a-space>
                <a-button type="primary" html-type="submit" class="submit"> 
                  {{$t("mine.share_10")}}
                </a-button>
                <a-button @click="handleReset" class="reset">
                  {{$t("mine.share_9")}}
                </a-button>
                <!-- <a-button type="dashed" class="flex">
                  <a-icon type="download" />
                  <span>{{$t("mine.share_8")}}</span>
                </a-button> -->
              </a-space>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <a-spin size="large" :spinning="isLoading">
      <div class="table-page-table-wrapper">
        <a-table 
          size="middle"
          row-key="id" 
          :columns="columns" 
          :data-source="orders"
          :pagination="pagination"
          @change="handleTableChange"
          :scroll="{ x: 900 }"
        >
          <template slot="product" slot-scope="text,record">
            <span>{{ record.name }} {{ record.spec_value }}</span>
          </template>
          <template slot="price" slot-scope="text,record">
            <span>{{ currency }} {{ record.real_price }}</span>
          </template>
          <template slot="rate" slot-scope="text,record">
            <span>{{ record.rate }}%</span>
          </template>
          <template slot="share" slot-scope="text,record">
            <span>{{ currency }} {{info.id === record.spread_uid ? record.spread_brokerage : record.spread_parent_brokerage}}</span>
          </template>
          <!-- <template slot="source" slot-scope="text,record">
            <span>{{record.share_nickname ||'-'}}</span>
          </template> -->
        </a-table>
      </div>
    </a-spin>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment';
import { mapState,mapGetters } from 'vuex'
import { DatePicker } from 'ant-design-vue';
import { UserShareListAPI } from '../api/api'
import locale_en from 'ant-design-vue/es/date-picker/locale/en_US';
import locale_cn from 'ant-design-vue/es/date-picker/locale/zh_CN';
import locale_tw from 'ant-design-vue/es/date-picker/locale/zh_TW';
Vue.use(DatePicker)
const INITIAL_PAGINATION = {
  current: 1,
  pageSize: 5,
  total:0
}
export default {
  data() {
    return {
      isLoading:false,
      queryForm:{
        create_date:[],
        order_no:'',
        status:undefined,
      },
      orders:[],
      locale:null,
      pagination:INITIAL_PAGINATION,
    };
  },
  computed:{
    ...mapState('user',['info']),
    ...mapGetters("config", ["currency"]),
    columns(){
      return [
        { title: this.$t("mine.share_11"),dataIndex: 'nickname' },
        { title: this.$t("mine.share_1"),dataIndex: 'time' },
        { title: this.$t("mine.share_2"),dataIndex: 'order_no',width: "160px" },
        { title: this.$t("mine.order_1"),dataIndex: 'name', scopedSlots: { customRender: 'product' } },
        { title: this.$t("mine.share_4"),dataIndex: 'status_name' },
        // { title: this.$t("mine.share_15"),dataIndex: 'share_type_name'},
        { title: this.$t("mine.share_13"),dataIndex: 'real_price', scopedSlots: { customRender: 'price' } },
        { title: this.$t("mine.share_17"),dataIndex: 'rate', scopedSlots: { customRender: 'rate' } },
        { title: this.$t("mine.share_14"),dataIndex: 'spread_uid', scopedSlots: { customRender: 'share' } },
        // { title: this.$t("mine.share_16"),dataIndex: 'share_nickname', scopedSlots: { customRender: 'source' } },
      ]
    }
  },
  mounted() {
    this.handleSubmit()
    this.locale = this.$i18n.locale === 'zh-cn'? locale_cn : this.$i18n.locale === 'zh-tw' ? locale_tw : locale_en
  },
  methods: {
    async handleSubmit(){
      const { create_date:[start,end], order_no, status } = this.queryForm;
      const params={
        ...(start&&{start_time:moment(start).startOf('day').valueOf(),}),
        ...(end&&{end_time:moment(end).startOf('day').valueOf(),}),
        ...(order_no&&{order_no}),
        ...(status&&{status}),
      }
      this.isLoading = true
      const {data:{list,count}} = await UserShareListAPI(params)
      this.pagination.total = count
      this.orders = list
      this.isLoading = false
    },
    async handleTableChange(pagination){
      this.isLoading = true
      const { create_date:[start,end], order_no, status } = this.queryForm;
      const { current, pageSize } = pagination
      this.pagination = pagination
      const params={
        ...(start&&{start_time:moment(start).startOf('day').valueOf(),}),
        ...(end&&{end_time:moment(end).startOf('day').valueOf(),}),
        ...(order_no&&{order_no}),
        ...(status&&{status}),
        limit:pageSize,
        page:current
      }
      const {data:{list}} = await UserShareListAPI(params)
      this.orders = list
      this.isLoading = false
    },
    handleReset(){
      this.$refs.QueryForm.resetFields()
      this.pagination = INITIAL_PAGINATION
      this.handleSubmit()
    },
  },
};
</script>
<style lang="scss" scoped>
#share{
  .query-form{
    margin: 20px 0;
    .ant-form {
      /deep/.ant-form-item{
        margin-bottom: 0;
        .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
        .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled){
          border-color: $color;
          box-shadow:none;
        }
        .ant-input:focus{
          border-color: $color;
          box-shadow:none;
        }
        .ant-select-open .ant-select-selection,.ant-select-selection:hover{
          border-color: $color;
          box-shadow:none;
        }
      }
      .submit{
        color: white;
        border-color: $color;
        background-color: $color;
      }
      .reset:hover{
        color: $color;
        border-color: $color;
      }
    }
  }
  .table-page-table-wrapper{
    /deep/ .ant-table {
      .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
        background-color: rgba(100, 46, 199, 0.1);
      }
    }
  }
}

</style>